<template>
  <div id="app">

    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
      <div class="container" style="padding: 0;">
        <a class="navbar-brand" href="/">Wild Bunch</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" @click.stop="$router.push({name: 'dashboard'})">{{$auth.isAuthenticated ? 'Hangar' : 'Login'}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://robertsspaceindustries.com/orgs/WLDB">Bewerbung</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://wild-bunch-sc.de/mining-calculator.html">MC</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <header class="masthead">
      <img id="slogan-image" src="../assets/images/slogan.png" alt="">
      <img id="title-image" src="../assets/images/title.png" alt="">
    </header>

    <section v-if="countdownStatus">
      <div class="wb-container">
        <div class="wb-countdown__wrapper">
          <div class="wb-countdown__countdown">
            <p class="wb-countdown__baseline">Countdown to Invictus Launch Week</p>
            <ul class="wb-countdown__units-wrapper">
              <li class="wb-countdown__unit wb-countdown__unit--days">
                <div class="wb-countdown__digit">00</div>
                <div class="wb-countdown__label">Days</div>
              </li>
              <li class="wb-countdown__unit wb-countdown__unit--hours">
                <div class="wb-countdown__digit">00</div>
                <div class="wb-countdown__label">Hours</div>
              </li>
              <li class="wb-countdown__unit wb-countdown__unit--minutes">
                <div class="wb-countdown__digit">00</div>
                <div class="wb-countdown__label">Minutes</div>
              </li>
            </ul>
          </div>
          <button class="wb-countdown__cta">
            <a href="https://www.google.com/calendar/render?action=TEMPLATE&amp;text=Invictus%20Launch%20Week%202951&amp;dates=20210521T160000Z/20210602T000000Z&amp;details=Join%20the%20UEE%20Naval%20fleet%20for%20Invictus%20Launch%20Week%202951,%20starting%20today!&amp;location=invictus2951.com&amp;sprop=&amp;sprop=name:" target="_blank" class="calendar-options__button calendar-options__button--google">
              Google
            </a>
            <div class="wb-countdown__cta-label">Add to Your Calendar</div>
            <a href="data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:https://robertsspaceindustries.com/comm-link/transmission/18099-Invictus-Launch-Week-2951-Save-The-Date%0ADTSTART:20210521T160000Z%0ADTEND:20210602T000000Z%0ASUMMARY:Invictus%20Launch%20Week%202951%0ADESCRIPTION:Join%20the%20UEE%20Naval%20fleet%20for%20Invictus%20Launch%20Week%202951,%20starting%20today!%0ALOCATION:invictus2951.com%0AEND:VEVENT%0AEND:VCALENDAR" class="calendar-options__button calendar-options__button--others">
              Others
            </a>
          </button>
        </div>
      </div>
    </section>

    <section style="background-color: #011e3e;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-2">
            <div class="py-5 px-2">
              <img class="img-fluid" src="../assets/images/history.png" alt="">
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="py-5 px-2">
              <h2 class="display-3 mb-4" style="color: whitesmoke;">Geschichte</h2>
              <p class="text" style="color: whitesmoke;">Wir sind ein Zusammenschluss deutschsprachiger Spieler, die primär ihren Spaß auf allen Arten von Capital-Ships haben möchten.
  Wir legen uns auf keine Spielausrichtung fest, sondern nehmen
  alle Möglichkeiten wahr, die uns geboten werden.
  Piraterie gehört einzig und allein nicht dazu und wird nicht geduldet.
  Ansonsten decken wir das komplette Spektrum an Spielmöglichkeiten ab.
  Die dafür benötigten Schiffe, vom Ausflugsdampfer bis zum Zerstörer,
  stehen uns alle mehrfach zur Verfügung.
  Natürlich werden wir nicht ausschließlich in Dickschiffen unterwegs sein,
  wenn wir sie aber brauchen oder uns der Sinn danach steht, können wir
  auf sie zurückgreifen….</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color: #01112c;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="py-5 px-2">
              <img class="img-fluid" src="../assets/images/fun.png" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="py-5 px-2">
              <h2 class="display-3 mb-4" style="color: whitesmoke;">Spaß</h2>
              <p style="color: whitesmoke;">Vor allem möchten wir Spaß mit einer aktiven, harmonischen Truppe haben
  und nicht unbedingt ein Imperium gründen, falls das zufällig doch passiert … auch gut</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color: #011e3e;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-2" style="display: flex; justify-content: center;">
            <div class="py-5 px-2">
              <img class="img-fluid" style="height: 250px;" src="../assets/images/logo.png" alt="">
            </div>
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="py-5 px-2">
              <h2 class="display-3 mb-4" style="color: whitesmoke;">Einer von uns</h2>
              <p style="color: whitesmoke;">Tritt Wild Bunch oder einfach nur unserer Community bei und
  besuche uns auf unserem <a href="https://discord.gg/HpSqVwWPkK" rel="nofollow">Discord</a> mit über 100 Spieler*innen.</p>
              <discord-invite />
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="py-5 bg-black">
      <div class="container" style="display: flex; justify-content: space-between;">
        <p class="m-0 text-center text-white small">Copyright &copy; Wild Bunch 2021</p>
        <a class="m-0 text-white small" href="/datenschutz.html">Datenschutzerklärung</a>
      </div>
    </footer>
  </div>
</template>

<script>
  import DiscordInvite from '../components/discordInvite';
  import '../../node_modules/bootstrap/js/dist/collapse';


export default {
  name: 'LandingPage',

  components: {
    DiscordInvite
  },

  data() {
    return {
      countdownStatus: false
    }
  },

  // mounted() {
  //   this.setCountdown();
  // },

  // methods: {
  //   async getCountdownConfig() {
  //     try {
  //       const response = await fetch('http://localhost:3000/countdown');
  //       return await response.json();
  //     } catch (error) {
  //       return null;
  //     }
  //   },
  //   async setCountdown() {
  //     const config = await this.getCountdownConfig();
  //     this.countdownStatus = config && config.status || false;
  //     if (!config || !config.status) {
  //       return;
  //     }

  //     let countdown; // setInterval function for countdown clock
  //     const daysUnit = document.querySelector('.wb-countdown__unit--days .wb-countdown__digit');// span element that displays the amount of days
  //     const hoursUnit = document.querySelector('.wb-countdown__unit--hours .wb-countdown__digit');// span element that displays the amount of hours
  //     const minutesUnit = document.querySelector('.wb-countdown__unit--minutes .wb-countdown__digit');// span element that displays the amount of minutes
  //     // const secondsUnit = document.querySelector('.seconds');// span element that displays the amount of seconds

  //     const startDate = new Date(2021, 4, 21, 18, 0, 0).getTime(); // initial date and time the countdown clock started from (Year, Month, Day, Hours, Minutes, Seconds,)
  //     startDate > Date.now() ? timer(startDate) : calculateFutureDate(startDate); // conditional statement that decides if the timer function should start with the start date or calculate another date
  //     // timer function takes in a date parameter in milliseconds
  //     function timer(date) {
  //       // countdown holds the entire timer functionality
  //       countdown = setInterval(()=>{
  //         const now = Date.now(); // current date and time
  //         const differenceInTime = date - now; // distance between current time and future time of event
  //         // checks timer to see if the distance is zero and if zero
  //         if (differenceInTime < 0) {
  //           clearInterval(countdown); // clear timer
  //           daysUnit.textContent = '00';
  //           hoursUnit.textContent = '00';
  //           minutesUnit.textContent = '00';
  //           return;
  //         }
  //         timeLeft(differenceInTime);// each iteration of setInterval send updated distance to timeLeft function
  //       }, 1000);// every 1 second
  //       const now = Date.now();
  //       timeLeft(startDate - now);// each iteration of setInterval send updated distance to timeLeft function
  //     }
  //     // timeLeft function takes a time as a parameter in milliseconds and displays it in Days, Hours, Minutes, and Seconds
  //     function timeLeft(time) {
  //       const days = Math.floor(time / (1000 * 60 * 60 * 24));// milliseconds into days
  //       const hours = Math.floor(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));// milliseconds into hours
  //       const minutes = Math.floor(time % (1000 * 60 * 60) / (1000 * 60));// milliseconds into minutes
  //       // const seconds = Math.floor(time % (1000 * 60) / 1000);// milliseconds into seconds
  //       // conditional added to each portion of the time that will be displayed adds a zero to the front of numbers < 10
  //       const displayDays = `${days < 10 ? '' : ''}${days}`;// days string that will be displayed
  //       const displayHours = `${hours < 10 ? '' : ''}${hours}`;// hours string that will be displayed
  //       const displayMinutes = `${minutes < 10 ? '' : ''}${minutes}`;// minutes string that will be displayed
  //       // const displaySeconds = `${seconds < 10 ? '0' : ''}${seconds}`;// seconds string that will be displayed

  //       // displays the time strings on the page individually
  //       daysUnit.textContent = displayDays;
  //       hoursUnit.textContent = displayHours;
  //       minutesUnit.textContent = displayMinutes;
  //       // secondsUnit.textContent = displaySeconds;
  //     }
  //     function calculateFutureDate(dateTochange) {
  //       const newDate = new Date(dateTochange);// converts it to date format
  //       const weeklyDate = newDate.setDate(newDate.getDate() + 7);// adds 7 days to that date
  //       timer(weeklyDate); // pass it to the timer function
  //     }
  //   }
  // }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900');
  @import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
</style>

<style lang="scss" scoped>
  @import "../../node_modules/bootstrap/scss/functions";
  @import "../../node_modules/bootstrap/scss/variables";
  @import "../../node_modules/bootstrap/scss/mixins";
  @import "../../node_modules/bootstrap/scss/reboot";
  @import "../../node_modules/bootstrap/scss/images";
  @import "../../node_modules/bootstrap/scss/grid";
  @import "../../node_modules/bootstrap/scss/transitions";
  @import "../../node_modules/bootstrap/scss/navbar";
  @import "../../node_modules/bootstrap/scss/utilities";
</style>

<style lang="scss" scoped>
  @import '../assets/scss/landingpage.scss';
</style>
